<template>
  <div id="app">
    <Home/>
    
  </div>
</template>

<script>

import Home from './views/home.vue'
export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
</style>
