<template>
  <div>
    <footer>
      <!-- 底部区域 -->
      <div class="footer-container">
        <div class="body">
          <div class="logo"><img class="logo" src="@/assets/logo.png" /></div>
          <div class="content width100">
            <p class="p">
              <a class="link">家庭维修服务预约/师傅入住: 188 2316 7601</a>
              <a class="link police" href="https://beian.miit.gov.cn">
                <img src="@/assets/police.png" />
                <span>蜀ICP备2024057664号</span>
              </a>
            </p>
          </div>
        </div>
        <div class="body2">期待每个人都能享受到"嘻嘻鲸"服务带来的美好生活</div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer-container {
  $padding-left: 20%;
  $padding-top: 30px;
  padding-left: $padding-left;
  padding-right: $padding-left;
  padding-top: $padding-top;
  padding-bottom: $padding-top;
  background-color: #fafafa;
}

.police {
  display: inline-flex;
  align-items: center;
}

.body {
  display: flex;
  align-items: center;
  color: #757575;

  .logo {
    width: 56px;
    height: 56px;
  }

  .content {
    padding-top: 4px;
    padding-left: 10px;
  }

  .p {
    display: flex;
    align-items: center;
  }

  .link {
    padding-right: 8px;
  }
}

.width100 {
  width: 100%;
}

.body2 {
  color: #d0d0d0;
  text-align: center;
  padding-top: 19px;
}
</style>