<template>
  <div class="home-container" id="boxFixed">
    <!-- 头部容器 -->
    <div :class="[isFixed ? 'headerOpacity' : '', 'home-header']">
      <div class="logo">
        <img src="@/assets/logo.png" alt="" />
      </div>
      <div class="content">
        <a @click="goAnchor('#imglist1')">官网</a>
        <a @click="goAnchor('#imglist2')">服务</a>
        <a @click="goAnchor('#imglist3')">品质</a>
      </div>
    </div>
    <!-- 图片List -->
    <div class="img-container">
      <div id="imglist1"><img src="@/assets/img1.jpg" alt="" /></div>
      <div id="imglist2"><img src="@/assets/img2.jpg" alt="" /></div>
      <div id="imglist3"><img src="@/assets/img3.jpg" alt="" /></div>
    </div>

    <el-backtop :bottom="60">
      <div class="scroop-top">
        <div class="icon"><i class="el-icon-top"></i></div>
        <div class="text">TOP</div>
      </div>
    </el-backtop>

    <fotter />
  </div>
</template>

<script>
import fotter from "@/components/fotter/footer";
export default {
  data() {
    return {
      isFixed: false,
    };
  },
  components: { fotter },
  //页面挂载后
  mounted() {
    //监听页面的滚动 然后用handleScroll这个方法进行对应的处理
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    //监听页面滚动事件
    handleScroll() {
      //第一步 获取当前滚动的距离
      let scrollTop =
        window.pageXOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //第二步 获取要滚动到顶部要吸附的元素偏移量(要滚动的元素的锚点位置)
      let offsetTop = document.querySelector("#boxFixed").offsetTop;
      //第三部 判断滚动的距离是否大于我们定义的锚点位置
      this.isFixed = scrollTop > offsetTop ? true : false;
      console.log(offsetTop);
    },
    //模拟锚点跳转
    goAnchor(selector) {
      //参数selector是id选择器
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>


<style lang="scss">
/* 父容器 */
.home-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* 头部容器 */
.home-header {
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding-left: 20%;

  /* justify-content: center; */
  .logo {
    width: 35px;
    height: 35px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    display: flex;
    font-weight: 600;
    color: #8dbbf7;

    a:nth-child(1) {
      color: #2660AD;
    }

    a {
      margin: 0 50px;
      cursor: pointer;
    }

    a:hover {
      opacity: 0.7;
      color: #2660AD;
    }
  }

  /* 图片列表 */
  .img-container div {
    width: 100vw;
    margin: 20px 0;
  }
}

.headerOpacity {
  background-color: black;
  opacity: 0.8;
}

// #imglist1,#imglist3 {
//   // margin-top: 0 !important;
//   // margin-bottom: -2px !important;
// }

.img-container div img {
  width: 100%;
  height: 100%;
}

.el-backtop {
  width: 42px;
  height: 62px;
  position: fixed;
  align-items: center;
  bottom: 20% !important;
  right: 4% !important;
}

.el-backtop .scroop-top {
  width: 100%;
  height: 100%;
  background-color: #bbbbbb;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  text-align: center;
  line-height: 10px;
  color: #fff;
  border-radius: 13px;

  .icon {
    font-size: 30px;
    margin-top: 15%;
  }

  .text {
    font-size: 16px;
  }
}
</style>